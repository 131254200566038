/*body,div{
  font-family: Arial;
  font-size: 12px;
  margin:0;
  padding: 0;
}*/


#chart_div{
  width: 100%;
  height:100vh;
  border: 1px solid #ccc;
  font-size: 11px;
}

.name_circle{
  stroke: #333333;
  stroke-width: 0.5;
}

.name_circle_label {
  fill: white;
  text-anchor: middle;
  font-size: 20px;
}

.title{
  text-anchor: middle;
  font-weight: bold;
}

.tree_rect{
  fill: white;
  stroke: #333333;
  stroke-width: 0.5;
}

.results_title{
  text-anchor: end;
}
.rect_line {
  stroke: #333333;
  stroke-width: 0.5;
}

.zoom_rect{
  stroke: #707070;
  fill: white;
  rx: 3;
  ry: 3;
}

.background_rect{
  fill: white;
  rx: 5;
  ry:5;
}
